header.header-main {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #c1a654;
}

header img.logo-bike-store{
    width: 150px;
    min-width: 100px;
}

.header__child{
    display: flex;
    align-items: center;
}


header .nav-btn:hover{
    color: #edf67c;
    transform: scale(1.1);
    transition: .5s;
}


@media (max-width: 340px) {
    img.logo-bike-store{
        width: 100px;
    }
}


@import url('https://fonts.googleapis.com/css2?family=Francois+One&family=Lato:wght@400;700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}

a{
  text-decoration: none;
  color: inherit;
}

/* Se resetean unos parámetros por defecto de bootstrap */
p, h1, h2, h3, h4, h5, ul{
  margin-bottom: 0;
  padding-left: 0;
}

img{
  max-width: 100%;
}

main h1, main h2, main h3, main h4{
  font-family: 'Francois One', sans-serif;
  font-weight: bold;
}

h2{
  text-align: center;
  margin: 20px 0;
}

button{
  font-family: lato !important;
  font-weight: bold !important;
}

a:hover{
  color: inherit;
}
section {
    margin: 60px 0;
}

.item-list{
    padding: 10px;
    max-height: 300px;
}

.img-list{
    border-radius: 30px;
}

.item-list__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

main h1 {
    text-align: center;
    margin: 3%;
    font-size: 3em;
    color: #c1a654;
}

@media (max-width: 683px) {

    main h1 {
        margin: 5% 5%;
        font-size: 2em;
    }

}

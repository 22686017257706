.item-list{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 25%;
    min-width: 250px;
    border-radius: 30px;
    text-align: center;
    overflow: hidden;
    min-height: 450px;
    margin: 20px;
    padding: 20px 0;
    box-shadow: 8px 8px 20px grey;
    transition: .4s;
}

.item-list:hover{
    transform: scale(1.1);
}

.item-list img{
    min-height: 180px;
    width: 100%;
    object-fit: cover;
}

.item-list h3{
    font-size: 1.2em;
}

@media (max-width: 579px) {
    .item-list {
        width: 80%;
    }
}